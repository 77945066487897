.Title {
    color: #506FA9;
}

.Container {
    padding-top: 25px;
}

.Experience {
    padding-top: 10px;
  text-align: left;

}