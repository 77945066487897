.App {
  align-self: center;
  text-align: center;
  margin: auto;
  margin-top: 100px;
  width: 70%;
}

.Card {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1), 0 12px 40px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
